import { AgentStage } from "@src/appV2/Agents";
import { licenseDataSchema } from "@src/appV2/Agents/types";
import { z } from "zod";

export const getWorkerResponseSchema = z.object({
  _id: z.string(),
  agentId: z.string().optional(),

  specialities: z
    .object({
      hasSedationExperience: z.boolean().optional(),
      hasTrayAssemblyExperience: z.boolean().optional(),
      experience: z.array(z.string()),
    })
    .optional(),
  preference: z.object({
    distance: z.number(),
    qualification: z.string(),
  }),
  attendance: z.object({
    sortMeta: z.number(),
    completedShifts: z.number(),
    cancelledShifts: z.number(),
    totalShifts: z.number(),
    consecutive: z.number(),
    consecutiveCancels: z.number(),
    percentage: z.number(),
  }),
  mobile: z.record(z.unknown()),
  onboardingFlags: z
    .object({
      isSignupInitiated: z.boolean().optional(),
      isSignupCompleted: z.boolean().optional(),
      signupStage: z.string().nullish(),
      signupCompletedAt: z.string().nullish(),
      /** onboardingWorkflow is deprecated - delete from server and remove here */
      onboardingWorkflow: z.string().optional(),
    })
    .optional(),
  rating: z
    .object({
      count: z.number(),
      value: z.number(),
    })
    .optional(),
  appRatingStatus: z
    .object({
      appRatingSubmitted: z.boolean(),
      appRating: z.string().nullish(),
      countPromptShown: z.number(),
      countCompletedShiftsWhenLastShownPrompt: z.number(),
    })
    .optional(),
  notificationPreferences: z.object({
    urgentShiftNotification: z.object({
      isOn: z.boolean(),
      snoozedOn: z.string().nullish(),
      snoozedForDays: z.number().nullish(),
      notificationFrequency: z.string(),
    }),
    off: z.boolean(),
    push: z.boolean(),
    email: z.boolean().optional(),
    sms: z.boolean(),
    marketingPush: z.boolean().optional(),
    priorityAccessPush: z.boolean(),
    marketingEmail: z.boolean().optional(),
    marketingSMS: z.boolean().optional(),
    long_code_sms: z.boolean(),
  }),
  name: z.string(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  phone: z.string(),
  socialSecurityNumber: z.string().nullish(),
  fullSocialSecurityNumber: z.string().optional(),
  rate: z.number(),
  stage: z.nativeEnum(AgentStage),
  showReferralAlert: z.boolean(),
  interestedAt: z.string().nullish(),
  noCallNoShow: z.number().optional(),
  signedDate: z.string().nullish(),
  active: z.boolean(),
  isNotificationAlertShown: z.boolean().optional(),
  isFirstTimeLoggedIn: z.boolean(),
  hasClaimedBonus: z.boolean().optional(),
  higherRate: z.string(),
  isTestAccount: z.boolean().optional(),
  clipboardAdvanceState: z.string().optional(),
  hideAsColleagueOnShifts: z.boolean().optional(),
  email: z.string(),
  employmentStatus: z.string(),
  dob: z.string().optional(),
  address: z.object({
    manualMsa: z.boolean(),
    city: z.string(),
    region: z.string().optional(),
    state: z.string(),
    stateCode: z.string(),
    country: z.string(),
    countryCode: z.string(),
    formatted: z.string(),
    line1: z.string().optional(),
    subpremise: z.string().optional(),
    metropolitanStatisticalArea: z.string(),
  }),
  geoLocation: z.object({
    coordinates: z.tuple([z.number(), z.number()]),
    type: z.literal("Point"),
  }),
  license: z
    .object({
      state: z.string(),
    })
    .optional(),
  userId: z.string(),
  isFirstSession: z.boolean().optional(),
  referralCode: z.string(),
  signedContractorAgreements: z.array(
    z.object({
      agreementVersion: z.string(),
      fullName: z.string().nullish(),
      signedDate: z.string(),
    })
  ),
  requirements: z.array(
    z.object({
      _id: z.string().optional(),
      expires: z.string().nullish(),
      requirement: z.string().optional(),
      updatedAt: z.string().optional(),
    })
  ),
  uploads: z.array(z.unknown()),
  backgroundCheckApplications: z.array(z.unknown()).optional(),
  licensesData: z.array(licenseDataSchema),
  createdAt: z.string().optional(),
  qualification: z.string().optional(),
  tmz: z.string(),
  isProfilePictureUploaded: z.boolean().optional(),
  onProbation: z.boolean().optional(),
  canBeOnboardedInStripe: z.boolean().optional(),
  paymentAccountInfo: z
    .object({
      enabled: z.boolean(),
      _id: z.string(),
      accountId: z.string(),
      status: z.string(),
      isTestAccount: z.boolean().optional(),
      createdAt: z.string(),
      updatedAt: z.string(),
      __v: z.number(),
      withdrawalFee: z.number(),
      gatewayTransferDisabled: z.boolean(),
    })
    .optional(),
  alreadyWorked: z.boolean().optional(),
  baseRate: z.number().optional(),
  requestedAccountDeletion: z
    .object({
      requested: z.boolean().nullish(),
      createdAt: z.string().nullish(),
      previousStage: z.string().nullish(),
    })
    .nullish(),
  isIdentifiedInSegment: z.boolean().optional(),
});

export type Worker = z.infer<typeof getWorkerResponseSchema>;
